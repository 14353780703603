@layer tailwind-base, antd;
@layer tailwind-base {
  @tailwind base;
}
/* @tailwind base; */
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(246, 248, 250) !important;
  overflow: auto;
  display: block !important;
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  -webkit-text-fill-color: #fafafa;
  transition: background-color 5000s ease-out 0.5s;
}

/* ::-webkit-scrollbar {
  display: none;
} */

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url("./assets/fonts/inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2")
    format("woff2");
}

.plus-sign::before,
.plus-sign::after {
  content: "";
  display: block;
  position: absolute;
  background-color: #c5ddff28;
}

.plus-sign::before {
  width: 50%;
  height: 1px;
  top: 50%;
  left: 25%;
  transform: translateY(-50%);
}

.plus-sign::after {
  height: 50%;
  width: 1px;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
}

.bg-moving,
.svelte-1nait2w {
  animation: svelte-1nait2w-move 5s ease-in-out infinite alternate;
  background-image: url(./assets/icons/common/bg-layer-blur.svg);
  background-position: fixed;
  background-repeat: no-repeat;
  background-size: contain;
  height: min(max(100vh, 1000px), 1500px);
  transform: translate(-54%, -5%) scale(4);
  width: min(max(100vh, 1000px), 1300px);
}

@keyframes svelte-1nait2w-move {
  0% {
    transform: translate(-68%, -5%) scale(4);
  }

  to {
    transform: translate(-48%) scale(4);
  }
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background-color: #d9d9d9;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #8c8c8c; /* 滚动条拇指颜色 */
  border-radius: 5px;
}

html {
  --ever-gray7: 140, 140, 140;
  --ever-brand1: 0, 100, 250;
  --ever-link: 43, 126, 251;
  --ever-success: 6, 167, 0;
  --ever-info1: 253, 78, 180;
  --ever-warning: 212, 127, 0;
  --ever-error: 250, 25, 0;
  --ever-blue1: 41, 30, 181;
  --ever-cyan: 0, 217, 240;
}

html[class="dark"] {
  --ever-gray7: 140, 140, 140;
  --ever-brand1: 0, 100, 250;
  --ever-link: 114, 171, 237;
  --ever-success: 139, 239, 153;
  --ever-info1: 224, 117, 180;
  --ever-warning: 219, 165, 7;
  --ever-error: 255, 90, 90;
  --ever-blue1: 49, 36, 217;
  --ever-cyan: 114, 237, 237;

  ::-webkit-scrollbar-track {
    background-color: #434343;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #595959; /* 滚动条拇指颜色 */
    border-radius: 5px;
  }
  .ant-upload-list {
    color: #fafafa;
    .ant-upload-icon {
      .anticon {
        color: #fafafa !important;
      }
    }
    .ant-upload-list-item-actions {
      .anticon {
        color: #fafafa !important;
      }
    }
  }
  .ever-custom-style-form {
    .ant-select {
      background: rgba(255, 255, 255, 0.04);
    }
    .ant-select-single .ant-select-selector {
      background-color: transparent !important;
      border: 1px solid rgba(255, 255, 255, 0.2) !important;
      border-radius: 4px;
    }

    .ant-select-arrow {
      color: #bfbfbf !important;
    }

    .ant-select-selection-item {
      color: #bfbfbf !important;
    }

    .ant-input {
      background-color: rgba(255, 255, 255, 0.04) !important;
      border-radius: 4px;
    }
    .ant-form-item-label {
      label {
        color: #fafafa;
      }
    }
    .ant-form-item-control-input-content {
      input {
        color: #fafafa;
      }
    }
    .ant-input-password {
      background-color: rgba(255, 255, 255, 0.04) !important;
      border-radius: 4px;

      .ant-input {
        background-color: transparent !important;
      }
    }

    .ant-picker {
      background-color: transparent !important;
      border-radius: 4px;
      color: #bfbfbf;
    }

    .ant-input::placeholder {
      color: #8c8c8c !important;
    }

    .ant-input-outlined,
    .ant-picker-outlined {
      border: 1px solid rgba(255, 255, 255, 0.2);
    }

    .ant-picker {
      background: rgba(255, 255, 255, 0.04);
      color: #fafafa !important;

      .ant-picker-separator,
      .ant-picker-suffix,
      .ant-input-password-icon,
      .ant-picker-clear {
        color: #bfbfbf !important;
      }

      .ant-picker-input > input {
        color: #fafafa !important;
      }
      .ant-picker-input > input::placeholder {
        color: #bfbfbf !important;
      }
    }

    .ant-input-password-icon {
      color: #bfbfbf !important;
    }

    input {
      caret-color: #fafafa;
    }

    textarea {
      caret-color: #fafafa;
      color: #fafafa;
    }
  }
}
